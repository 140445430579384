const HomePageSkeleton = () => {

    const PostSkeleton = () => {
        return <div className="flex flex-col space-y-3 px-5 w-full sm:w-[500px]">
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[400px] mb-2.5"></div>
            <div className="flex items-center justify-center bg-gray-300 sm:w-[450px] h-[500px] rounded dark:bg-gray-700">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>

            </div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[350px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[400px] mb-2.5"></div>
        </div>
    }

    const ReviewPreviewSkeleton = () => {
        return <div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-[90%] max-w-[400px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-[80%] max-w-[380px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-[90%] max-w-[380px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-[80%] max-w-[380px] mb-2.5"></div>
        </div>
    }
    return (
        <div role="status" className="space-y-8 animate-pulse overflow-hidden flex justify-center">
            <div className="h-screen overflow-hidden flex flex-col space-y-4 pt-5 w-full items-center max-w-[500px]">
                <PostSkeleton />
                <PostSkeleton />
                <PostSkeleton />
            </div>
            <div className="flex-1 hidden space-y-4 max-w-[400px] lg:flex lg:flex-col">
                <ReviewPreviewSkeleton />
                <ReviewPreviewSkeleton />
                <ReviewPreviewSkeleton />
                <ReviewPreviewSkeleton />
            </div>

        </div>
    )
}

export default HomePageSkeleton